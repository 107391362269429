import { Box, Grid, Icon, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BoxDefaultContent from '../components/box/BoxDefaultContent';
import BoxSection from '../components/box/BoxSection';
import BoxSectionTeam from '../components/box/BoxSectionTeam';
import BreakpointUtils from '../utils/BreakpointUtils';
import SponsorSegment from '../components/SponsorSegment';

interface ImageProps {
  src: string;
  name: string;
  homepage?: string;
}

function AboutPageImage(props: ImageProps) {

  const imgStyle = {
    borderRadius: '50%',
    width: BreakpointUtils.lessThanSmall() ? '150px' : '200px',
    height: BreakpointUtils.lessThanSmall() ? '150px' : '200px',
    margin: '.5rem',
  }

  if (props.homepage === undefined) {
    return (
      <Box sx={{
        // width: '250px',
        textAlign: 'center',
        // float: 'left',
        // margin: '20px',
      }}>
        <img src={props.src} style={imgStyle} />
        <Typography>{props.name}</Typography>
        <Typography>University of Tübingen</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{
      // width: '250px',
      textAlign: 'center',
      // float: 'left',
      // margin: '20px',
    }}>
      <Link color='secondary' href={props.homepage} target='_blank'>
        <img src={props.src} style={imgStyle} />
        <Typography>{props.name}</Typography>
        <Typography>University of Tübingen</Typography>
      </Link>
    </Box>
  )
}

function AboutPageTeamImage(props: ImageProps) {

  const imgStyle = {
    width: BreakpointUtils.lessThanSmall() ? '90%' : '100%',
    height: BreakpointUtils.lessThanSmall() ? '90%' : '100%',
    margin: '.5rem',
  }

  if (props.homepage === undefined) {
    return (
      <Box sx={{
        // width: '250px',
        textAlign: 'center',
        // float: 'left',
        // margin: '20px',
      }}>
        <img src={props.src} style={imgStyle} />
      </Box>
    )
  }

}

export default function AboutPage() {
  return (
    <BoxDefaultContent sx={{ maxWidth: '900px' }}>

      <Typography sx={{ mt: 2 }}>
        If you have any feedback, bug reports or feature requests please send them to
        <a href="mailto:scholar-inbox@googlegroups.com"> scholar-inbox@googlegroups.com</a>
      </Typography>
      <AboutPageTeamImage src="image/team/team.png" name="Our Team" />
      <BoxSectionTeam heading={'Team Lead'} sx={{ mt: 2, }}>
        <AboutPageImage src="image/team/andreas.jpg" name="Andreas Geiger" homepage="https://cvlibs.net/about" />
      </BoxSectionTeam>

      <BoxSectionTeam heading={'Core Team'} sx={{ mt: 2, }}>
        <AboutPageImage src="image/team/markus.jpg" name="Markus Flicke" />
        <AboutPageImage src="image/team/vitalii__200x200.jpg" name="Vitalii Protsenko" />
        <AboutPageImage src="image/team/madhav.jpg" name="Madhav Iyengar" />
        <AboutPageImage src="image/team/haoyu.jpg" name="Haoyu He" />
      </BoxSectionTeam>

      <BoxSectionTeam heading={'Student Assistants'} sx={{ mt: 2, }}>
        <AboutPageImage src="image/team/illja.jpg" name="Illia Shakun" />
        <AboutPageImage src="image/team/bora.jpeg" name="Bora Kargi" />
      </BoxSectionTeam>

      <BoxSectionTeam heading={'Past Team Members'} sx={{ mt: 2, }}>
        <AboutPageImage src="image/team/jovan.jpg" name="Jovan Cicvaric" />
        <AboutPageImage src="image/team/partha__200x200.jpg" name="Partha Ghosh" />
        <AboutPageImage src="image/team/lewin.jpg" name="Lewin Scholz" />
      </BoxSectionTeam>

      <SponsorSegment sx={{ my: 2, pb: 2 }} />

    </BoxDefaultContent>
  )
}