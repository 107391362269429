import { Dayjs } from "dayjs";

/**
 * Checks if date is the weekend date
 * @param date Dayjs date
 * @returns true if date falls on Saturday or Sunday
 */
export function isWeekend(date: Dayjs): boolean {
  return date.day() === 0 || date.day() === 6;
}

/**
 * Checks if 1st date is after 2nd date (date after last digest)
 * @param date 
 * @param lastDigestDate 
 * @returns true if @date is after @lastDigestDate
 */
export const dateAfterLastDigest = (date: Dayjs, lastDigestDate: Dayjs) => {
  return date.isAfter(lastDigestDate, 'day');
}

/**
 * !!ATTENTION!!
 * JUST RETURNS DOUBLE ARRAY FILLED WITH FALSE. (never auto expand anything based on currentime)
 * to reenable this functionality remove the return on line 36.
 * 
 * Converts conference dates representation of boolean[][], true element is the event coming up
 * used for collapse controller. Closest event has to be a poster_session
 * @param dates 
 * @param current_date 
 * @returns array of arrays
 */
export function getInitialEventCollapseControllerBasedOnCurrentDate(dates: {time: string, type: string}[][], current_date: Date): boolean[][] {
  const parsedDates = dates.map(dateArray => dateArray.map(date => { return {'date': new Date(date.time), 'type': date.type}}));

  return parsedDates.map(dateArray => dateArray.map(date => false));

  // Filter the dates that are in the future from the current date. Filter only poster sessions
  const futureDates = parsedDates.flatMap(dateArray => dateArray.filter(date => (date.date > current_date) && date.type === 'poster_session'));
  if (futureDates.length > 0) {
    // Get the first date from the sorted list, which will be the next closest date in the future from the current date.
    const closestDate = futureDates[0];
    // Convert the dates list to a list of lists of booleans, where each inner list represents a date, and all entries are false except for the closest date, which is true.
    const boolDates = parsedDates.map(dateArray => dateArray.map(date => date.date.getTime() === closestDate.date.getTime()));
    return boolDates;
  } else {
    // if future date not found return correct list of falses
    return parsedDates.map(dateArray => dateArray.map(date => false));
  }
}