import { Box, IconButton, Link, Paper as MuiPaper, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { colourListToColour } from '../utils/relevanceUtils';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { MakePaperRating, MarkPaperAsRead } from '../services/DigestService';
import { SCHOLAR_INBOX_PAPERS_URL } from './Paper';
import theme from '../theme';

const DEFAULT_READ_COLOUR = 'rbga(128,128,128,0.25)';
const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export interface TrendingPaperInfo {
  paperId?: number,
  paperTitle: string,
  author: string,
  total_reads: number,
  likes: number,
  relevance: number,
  colour: number[];
  read?: number,
  paperLink?: string,
  rating?: number,
  is_cached?: boolean,
  useScholarInboxPdfServer?: boolean,
  is_cached_filename?: string,
  digestDate: string,
  handleThumbsDownClick?: (paperId: number, totalLikes: number, upVoted: boolean, downVoted: boolean, setUpVoted: (value: boolean) => void, setDownVoted: (value: boolean) => void, setTotalLikes: (total: number) => void, totalRead: number, isRead: boolean, setRead: (value: boolean) => void, setTotalRead: (total: number) => void) => void,
  handleThumbsUpClick?: (paperId: number, totalLikes: number, upVoted: boolean, downVoted: boolean, setUpVoted: (value: boolean) => void, setDownVoted: (value: boolean) => void, setTotalLikes: (total: number) => void, totalRead: number, isRead: boolean, setRead: (value: boolean) => void, setTotalRead: (total: number) => void) => void,
  setPaperAsRead?: (paperId: number, totalRead: number, isRead: boolean, setRead: (value: boolean) => void, setTotalRead: (total: number) => void) => void,
}

export interface TrendingBarProps {
  trendingPapers: TrendingPaperInfo[],
}

function TrendingPaper(trendingProps: TrendingPaperInfo) {
  const { paperId, paperTitle, author, likes,
    total_reads, relevance, colour, read, paperLink, rating, is_cached, useScholarInboxPdfServer, is_cached_filename, digestDate } = trendingProps
  const [upVoted, setUpVoted] = useState<boolean>(false);
  const [downVoted, setDownVoted] = useState<boolean>(false);
  const [isRead, setRead] = useState<boolean>(false);
  const [totalLikes, setTotalLikes] = useState<number>(likes);
  const [totalRead, setTotalRead] = useState<number>(total_reads);

  const handleThumbsUpClick = () => {
    trendingProps.handleThumbsUpClick(trendingProps.paperId, totalLikes, upVoted, downVoted, setUpVoted, setDownVoted, setTotalLikes, totalRead, isRead, setRead, setTotalRead);
  };
  const handleThumbsDownClick = () => {
    trendingProps.handleThumbsDownClick(trendingProps.paperId, totalLikes, upVoted, downVoted, setUpVoted, setDownVoted, setTotalLikes, totalRead, isRead, setRead, setTotalRead);
  };
  const setPaperAsRead = () => {
    trendingProps.setPaperAsRead(trendingProps.paperId, totalRead, isRead, setRead, setTotalRead);
  };


  useEffect(() => {
    if (trendingProps.rating === 1) setUpVoted(true);
    if (trendingProps.rating === -1) setDownVoted(true);
    if (trendingProps.read) setRead(true);
  }, []);

  let thumbsUpIcon = upVoted ? <ThumbUpAltIcon color='secondary' /> : <ThumbUpOffAltIcon />;
  let thumbsDownIcon = downVoted ? <ThumbDownIcon color='secondary' /> : <ThumbDownOffAltIcon />;

  return (
    <Box sx={{
      backgroundColor: colourListToColour(colour),
      borderRadius: '7px',
      m: '2px',
      fontSize: '14px',
      display: { xs: 'none', md: 'block' },
    }}>
      <Box sx={{
        paddingLeft: '7px',
        display: { xs: 'none', md: 'flex' },
        borderLeftColor: (isRead) ? '' : '#f44336!important',
        borderLeft: (isRead) ? 'none' : 'solid',
        borderLeftWidth: (isRead) ? '0' : '3px',
        marginLeft: (isRead) ? '3px' : '0',
        borderRadius: '7px',
        alignItems: 'center',
      }}>
        <Tooltip title='Digest Date' placement="top">
          <Typography sx={{ miwWidth: '60px', textAlign: 'right', fontSize: '14px'}}>{monthNames[new Date(digestDate).getMonth()]}&nbsp;{new Date(digestDate).getDate()}&nbsp;|</Typography>
        </Tooltip>
        <Box onClick={setPaperAsRead} sx={{ overflow: 'hidden', maxWidth: 'calc(100% - 25.7em)' }}>
          <Link
            href={trendingProps.is_cached && trendingProps.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + trendingProps.is_cached_filename : trendingProps.paperLink}
            target={'_blank'}
            sx={{ textDecoration: 'none', color: 'black' }}
          >
            <Typography component={'pre'} fontSize={'14px'}> {paperTitle} | <strong>{author}</strong></Typography>
          </Link>
        </Box>
        <Box sx={{ marginLeft: 'auto', marginRight: '1px', pr: '7px', display: 'flex', width: '14.7em' }}>
          <Typography component={'pre'} fontSize={'14px'}>Likes: <Box sx={{ width: '1.8em', textAlign: 'center', display: 'inline-block' }}>{totalLikes}</Box> Reads: <Box sx={{ width: '1.8em', textAlign: 'center', display: 'inline-block' }}>{totalRead}</Box></Typography>
          <IconButton onClick={handleThumbsUpClick} sx={{ transform: 'scale(0.8)', height: '20px', width: '20px', ml: '15px' }}>
            {thumbsUpIcon}
          </IconButton>
          <IconButton onClick={handleThumbsDownClick} sx={{ transform: 'scale(0.8)', height: '20px', width: '20px', ml: '5px' }}>
            {thumbsDownIcon}
          </IconButton>
        </Box>
      </Box>
    </Box >
  )
}

export default function TrendingBar(trendingBarProps: TrendingBarProps) {
  const [trendingPapers, setTrendingPapers] = useState<TrendingPaperInfo[]>([]);
  const [needsUpdate, setNeedsUpdate] = useState<number>(0);

  useEffect(() => {
    setTrendingPapers(trendingBarProps.trendingPapers);
  }, [trendingBarProps.trendingPapers]);

  const sortTrendingBar = () => {
    let sortedPapers = [...trendingPapers];
    sortedPapers.sort((a, b) => (b.total_reads || 0) - (a.total_reads || 0));
    setTrendingPapers(sortedPapers);
  }

  const handleThumbsUpClick = (paperId: number, totalLikes: number, upVoted: boolean, downVoted: boolean, setUpVoted: (value: boolean) => void, setDownVoted: (value: boolean) => void, setTotalLikes: (total: number) => void, totalRead: number, isRead: boolean, setRead: (value: boolean) => void, setTotalRead: (total: number) => void) => {
    const rating = upVoted ? 0 : 1;
    MakePaperRating(rating, paperId?.toString());
    setUpVoted(!upVoted);
    setDownVoted(false);
    setPaperAsRead(paperId, totalRead, isRead, setRead, setTotalRead);
    setTotalLikes(totalLikes + (rating === 1 ? 1 : -1));
  };

  const handleThumbsDownClick = (paperId: number, totalLikes: number, upVoted: boolean, downVoted: boolean, setUpVoted: (value: boolean) => void, setDownVoted: (value: boolean) => void, setTotalLikes: (total: number) => void, totalRead: number, isRead: boolean, setRead: (value: boolean) => void, setTotalRead: (total: number) => void) => {
    const rating = downVoted ? 0 : -1;
    MakePaperRating(rating, paperId?.toString());
    setUpVoted(false);
    setDownVoted(!downVoted);
    setPaperAsRead(paperId, totalRead, isRead, setRead, setTotalRead);
    setTotalLikes(totalLikes + (upVoted ? -1 : 0));
  };

  useEffect(() => {
    sortTrendingBar();
  }, [needsUpdate])

  const setPaperAsRead = (paperId: number, totalRead: number, isRead: boolean, setRead: (value: boolean) => void, setTotalRead: (total: number) => void) => {
    MarkPaperAsRead(paperId?.toString());
    setRead(true);
    let total_reads: number = totalRead + (isRead ? 0 : 1);
    setTotalRead(total_reads);
    const updatedTrendingPapers = trendingPapers.map((item) => {
      if (item.paperId === paperId) {
        return { ...item, total_reads };
      } else {
        return item;
      }
    });
    setTrendingPapers(updatedTrendingPapers);
    if (total_reads !== totalRead) {
      setNeedsUpdate(needsUpdate + 1)
    }
  };

  return (
    <>
      {trendingPapers.length > 0 && (
        <MuiPaper
          elevation={16}
          sx={{
            position: 'relative',
            mb: '8px', mt: '12px',
            boxShadow: '0 0 10px #ccc', borderRadius: '10px',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            maxWidth: theme.maxWidthContainer
          }}>
          {
            trendingPapers.length > 0 &&
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {trendingPapers.map((trendingPaper) => (
                <TrendingPaper {...trendingPaper} key={trendingPaper.paperId} handleThumbsDownClick={handleThumbsDownClick} handleThumbsUpClick={handleThumbsUpClick} setPaperAsRead={setPaperAsRead} />
              ))}
            </Box>
          }
        </MuiPaper>
      )}
    </>
  )
}

